  <!-- B container -->
  <div class="container py-3">
    <div id="huisje" class="border rounded-3 bg-primary x-top-buffer">
      <div class="row">
        <div class="py-4 ms-auto text-center"><h6 class="display-6 fw-normal">Gezellige 6-persoons bungalow</h6>
          <p class="fs-5 text-muted">Hier ziej' nog 's echte sterren!</p></div>
      </div>
      <div class="row row-cols-1 justify-content-between mb-3">
        <div class="col d-flex align-items-stretch">
          <div class="card w-100 m-4 rounded-3 shadow-sm">
<!--            <div class="card-header py-3 text-center"><h4 class="my-0 fw-normal">Hier ziej' nog 's echte sterren!</h4></div>-->
            <div class="card-body">
              <p><small class="text-muted fw-ligth"></small>
              <p>Begane grond</p>
              <p><small class="text-muted fw-ligth">
                Het vakantiehuis ligt in een rustig gedeelte van het park en heeft een heerlijk grote tuin, omzoomd door hoge beplanting voor extra privacy. Er is ruime parkeergelegenheid vlakbij het huis. Je komt binnen via een hal die toegang geeft tot toilet, badkamer en de woonkamer. Door twee extra dakramen en een grote achterpui, is de woonkamer het hele jaar door lekker licht en zonnig. De eettafel is bij deze pui geplaatst en vormt daarmee een heerlijke plek voor ontbijt, diner of gezelschapsspel. Met uitzicht op het vogelvoederstation, kun je genieten van de komst van diverse vogels of zelfs eekhoorns (dus neem wat vogelzaad mee!).</small></p>
              <p><small class="text-muted fw-ligth">
                De open keuken is voorzien van koelkast met vriesvak, combi-oven/magnetron, 4-pits gaskookplaat, afzuigkap en vaatwasmachine. Het is heerlijk ontspannen op de goed-zittende, stoffen banken of luxe, verstelbare fauteuil, genietend van een film op de smart-tv of dvd-speler. Uiteraard beschikt het huisje over goede WiFi. Beneden bevindt zich nog een slaapkamer met twee naast elkaar geplaatste boxspring bedden, kledingkast en een praktisch bureau. Hier kun je dus ook in alle rust (thuis) werken. </small></p>
              <p><small class="text-muted fw-ligth">
                De achterpui geeft direct toegang tot het zonneterras op het zuid-westen, waar u heerlijk kan genieten van de middag- en avondzon. Voor de ochtendzon, ligt aan de achterzijde van de royale tuin ook een terras. Dit terras biedt prachtig vrij uitzicht op het Drentse platteland. Aan de achterzijde is ook de berging voorzien van wasmachine. Een tuinhuisje (niet toegankelijk), biedt een afdak en electra voor het beschut stallen (en laden) van fietsen.</small></p>

              <p>Eerste verdieping</p>
              <p><small class="text-muted fw-ligth">Via een ruime overloop voorzien van vaste kasten, bereik je de twee grote slaapkamers. Elke slaapkamer is voorzien van twee losstaande, lange (210cm) boxspring bedden. Elk bed heeft een eigen dimbaar (lees)lampje om lekker lezen of te gebruiken als nachtlampje voor de kleintjes. Een traphekje voorkomt dat kleine kinderen ongewenst van boven naar beneden kunnen gaan. De slaapkamer aan de voorzijde geeft toegang tot een klein balkon, waar je heerlijk van het late middagzonnetje kan genieten.</small></p>
              <p><small class="text-muted fw-ligth">En als de zon valt, kan je bij helder weer genieten van een prachtige sterrenhemel, die je alleen op het platteland zo kan zien!</small></p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="container">
    <br/>
    <blockquote class="blockquote text-center">
      <p class="mb-2">"Hoi wij vonden het heel leuk"</p>
      <footer class="blockquote-footer">Sarah's mening (8 jaar)</footer>
    </blockquote>
    <br/>
  </div>

  <!-- photos -->
  <div class="container py-0">
    <div class="border rounded-3 bg-primary">
      <div class="container-fluid">
        <div class="row row-cols-1 row-cols-sm-2 row-cols-md-3 row-cols-lg-3 g-6">
          <div class="col">
            <div class="card">
              <img src="assets/img/IMG_4609-min_1200x800.JPG" class="img-thumbnail rounded mx-auto d-block" alt="...">
            </div>
          </div>
          <div class="col">
            <div class="card">
              <img src="assets/img/IMG_4611-min_1200x800.JPG" class="img-thumbnail rounded mx-auto d-block" alt="...">
            </div>
          </div>
          <div class="col">
            <div class="card">
              <img src="assets/img/IMG_4607-min_1200x800.JPG" class="img-thumbnail rounded mx-auto d-block" alt="...">
            </div>
          </div>
        </div>
      </div>
      <div class="container-fluid">
        <div class="row row-cols-1 row-cols-sm-2 row-cols-md-3 row-cols-lg-3 g-6">
          <div class="col">
            <div class="card">
              <img src="assets/img/IMG_4606-min_1200x800.JPG" class="img-thumbnail rounded mx-auto d-block" alt="...">
            </div>
          </div>
          <div class="col">
            <div class="card">
              <img src="assets/img/IMG_4615-min_1200x800.JPG" class="img-thumbnail rounded mx-auto d-block" alt="...">
            </div>
          </div>
          <div class="col">
            <div class="card">
              <img src="assets/img/IMG_4076%20Medium.jpeg" class="img-thumbnail rounded mx-auto d-block" alt="...">
            </div>
          </div>
        </div>
      </div>
      <div class="container-fluid">
        <div class="row row-cols-1 row-cols-sm-2 row-cols-md-3 row-cols-lg-3 g-6">
          <div class="col">
            <div class="card">
              <img src="assets/img/IMG_4613-min_1200x800.JPG" class="img-thumbnail rounded mx-auto d-block" alt="...">
            </div>
          </div>
          <div class="col">
            <div class="card">
              <img src="assets/img/IMG_4614-min_1200x800.JPG" class="img-thumbnail rounded mx-auto d-block" alt="...">
            </div>
          </div>
          <div class="col">
            <div class="card">
              <img src="assets/img/IMG_4060 Medium.jpeg" class="img-thumbnail rounded mx-auto d-block" alt="...">
            </div>
          </div>
        </div>
      </div>
      <div class="container-fluid">
        <div class="row row-cols-1 row-cols-sm-2 row-cols-md-3 row-cols-lg-3 g-6">
          <div class="col">
            <div class="card">
              <img src="assets/img/IMG_4054%20Medium.jpeg" class="img-thumbnail rounded mx-auto d-block" alt="...">
            </div>
          </div>
          <div class="col">
            <div class="card">
              <img src="assets/img/IMG_4056%20Medium.jpeg" class="img-thumbnail rounded mx-auto d-block" alt="...">
            </div>
          </div>
          <div class="col">
            <div class="card">
              <img src="assets/img/IMG_4064%20Medium.jpeg" class="img-thumbnail rounded mx-auto d-block" alt="...">
            </div>
          </div>
        </div>
      </div>
      <div class="container-fluid">
        <div class="row row-cols-1 row-cols-sm-2 row-cols-md-3 row-cols-lg-3 g-6">
          <div class="col">
            <div class="card">
              <img src="assets/img/IMG_4592-min.JPG" class="img-thumbnail rounded mx-auto d-block" alt="...">
            </div>
          </div>
          <div class="col">
            <div class="card">
              <img src="assets/img/IMG_4590-min.JPG" class="img-thumbnail rounded mx-auto d-block" alt="...">
            </div>
          </div>
          <div class="col">
            <div class="card">
              <img src="assets/img/IMG_4601-min_1200x800.JPG" class="img-thumbnail rounded mx-auto d-block" alt="...">
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- /photos -->


