<header>
  <!-- maintain .nav component skeleton governed by Bootstrap -->
  <nav class="navbar navbar-expand-md fixed-top navbar-light bg-primary">
    <!-- A container -->
    <div class="container">
      <a class="navbar-brand" routerLink="/">
        <span class="fw-lighter">Drentshuisje.nl</span></a>
      <a class="navbar-brand" routerLink="/">
        <span class="fw-lighter"><small class="text-muted"> Geniet'n op zien Drents!</small></span></a>

      <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
        <span class="navbar-toggler-icon"></span>
      </button>

      <div class="collapse navbar-collapse" id="navbarSupportedContent">
        <ul class="navbar-nav ms-auto justify-content-end">
          <li class="nav-item active">
            <!-- routerLink does not work.. -->
            <a class="nav-link" routerLink="/huisje" [routerLink]="['/huisje']">Het huisje</a>
          </li>
          <li class="nav-item active">
            <a class="nav-link" routerLink="/park">Het vakantiepark</a>
          </li>
          <li class="nav-item">
            <a class="btn btn-success" target="_blank" href="https://boeken.roompot.nl/roompot/object/resourceid/41337825/objectid/8819333/dc/DBEI2/rc/aangebrachteigenaa/lan/nl/ownerid/277918437/myenv/true">Reserveer &raquo;</a>
          </li>
        </ul>
      </div>
    </div>
  </nav>
</header>
