import {Component, OnInit} from '@angular/core';

@Component({
  selector: 'app-thermostat',
  templateUrl: './huisje.component.html',
})
export class HuisjeComponent implements OnInit {

  constructor() {
  }

  ngOnInit(): void {
  }

}
