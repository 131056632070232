<div class="p-4 mb-2 x-jumbotron"><!-- .x-jumbotron mapped in _variables.scss -->
  <!-- B container -->
  <div class="container py-5">
    <div class="row text-white">
      <div class="mx-auto">
        <h1 class="text-uppercase">
          Kom
          <br>
          <span class="txt-rotate fw-lighter m-2" data-period="10" data-rotate="[
                          &quot;Fietsen.&quot;, &quot;Zwemmen.&quot;, &quot;Wandelen.&quot;, &quot;Thuis 'werken'.&quot;, &quot;Tot rust.&quot; ]"><span class="wrap"></span></span>
        </h1>
      </div>
    </div>
  </div>
</div>

<!-- callout -->
<div class="container">
  <br/>
  <blockquote class="blockquote text-center">
    <p class="mb-2">"Het huisje was top! Schoon, van alle gemakken voorzien en het was heerlijk rustig"</p>
    <footer class="blockquote-footer">Tom, Ali en kinderen</footer>
  </blockquote>
  <br/>
</div>

<div class="p-0 mb-2">
    <div>
      <!--Google map-->
      <div class="x-map-responsive">
        <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d38518.98347522959!2d6.5740704783929536!3d52.88656371675061!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47c8218a7d1945e3%3A0xf676296e70ead10a!2sHet%20Zwiggelterveld%2041%2C%209433%20VA%20Zwiggelte!5e0!3m2!1snl!2snl!4v1681136980792!5m2!1snl!2snl" width="800" height="600" style="border:0;" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
      </div>
      <!--Google Maps-->
    </div>
</div>

