  <!-- B container -->
  <div class="container py-3">
    <div id="huisje" class="border rounded-3 bg-primary x-top-buffer">
      <div class="row">
        <div class="py-4 ms-auto text-center"><h6 class="display-6 fw-normal">Bungalowpark Hart van Drenthe</h6>
          <p class="fs-5 text-muted">Doe moar kalm an, ’t is vakantie!</p></div>
      </div>
      <div class="row row-cols-1 justify-content-between mb-3">
        <div class="col d-flex align-items-stretch">
          <div class="card w-100 m-4 rounded-3 shadow-sm">
            <!--<div class="card-header py-3 text-center"><h4 class="my-0 fw-normal">Doe moar kalm an, ’t is vakantie!</h4></div>-->
            <div class="card-body">
              <p><small class="text-muted fw-ligth"></small>
              <p>Het Hart van Drenthe</p>
              <p><small class="text-muted fw-ligth">
                'Doe moar kalm an, ’t is vakantie!' is het motto van bungalowpark Het Hart van Drenthe. Dit gezellige park heeft dan ook alles wat je nodig hebt voor een ontspannen vakantie of een heerlijk weekendje weg. Zo kunnen bezoekers  genieten van het overdekte, verwarmde zwembad met kinderbadje of van een heerlijk kopje koffie met taart bij 'De Tuinkamer'. Voor de jongste bezoekers zijn er diverse speeltuinen, met air trampoline, panna voetbalveldje of kabelbaan. Ook midget golf of jeu de boules is mogelijk.</small></p>
              <p><small class="text-muted fw-ligth">De hartelijke receptie biedt naast een welkom ontvangst, een leuk aanbod aan lokale streekproducten. Hier kun je brood bestellen en tijdens het hoogseizoen en de reguliere vakanties is hier doorgaans ook lekkere patat met snacks te bestellen. In de gezellige en zonnige “Tuinkamer” kun je van een heerlijke kopje koffie met appeltaart genieten. Loop ook zeker even het "Belevingspad", deze start bij het park.
              </small></p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <!-- callout -->
  <div class="container">
    <br/>
    <blockquote class="blockquote text-center">
      <p class="mb-2">"Het park is fijn rustig en de omgeving prachtig om heerlijke fietstochtjes te maken!"</p>
      <footer class="blockquote-footer">Harm en Loes</footer>
    </blockquote>
    <<br/>
  </div>

  <!-- photos -->
  <div class="container py-0">
    <div class="border rounded-3 bg-primary">
      <div class="container-fluid">
        <div class="row row-cols-1 row-cols-sm-2 row-cols-md-3 row-cols-lg-3 g-6">
          <div class="col">
            <div class="card">
              <img src="assets/img/IMG_4650-min_1200x800.JPG" class="img-thumbnail rounded mx-auto d-block" alt="...">
            </div>
          </div>
          <div class="col">
            <div class="card">
              <img src="assets/img/IMG_4651-min_1200x800.JPG" class="img-thumbnail rounded mx-auto d-block" alt="...">
            </div>
          </div>
          <div class="col">
            <div class="card">
              <img src="assets/img/IMG_4658-min_1200x800.JPG" class="img-thumbnail rounded mx-auto d-block" alt="...">
            </div>
          </div>
        </div>
      </div>
      <div class="container-fluid">
        <div class="row row-cols-1 row-cols-sm-2 row-cols-md-3 row-cols-lg-3 g-6">
          <div class="col">
            <div class="card">
              <img src="assets/img/IMG_4673-min_1200x800.JPG" class="img-thumbnail rounded mx-auto d-block" alt="...">
            </div>
          </div>
          <div class="col">
            <div class="card">
              <img src="assets/img/IMG_4666-min_1200x800.JPG" class="img-thumbnail rounded mx-auto d-block" alt="...">
            </div>
          </div>
          <div class="col">
            <div class="card">
              <img src="assets/img/IMG_4623-min_1200x800.JPG" class="img-thumbnail rounded mx-auto d-block" alt="...">
            </div>
          </div>
        </div>
      </div>
      <div class="container-fluid">
        <div class="row row-cols-1 row-cols-sm-2 row-cols-md-3 row-cols-lg-3 g-6">
          <div class="col">
            <div class="card">
              <img src="assets/img/IMG_4633-min_1200x800.JPG" class="img-thumbnail rounded mx-auto d-block" alt="...">
            </div>
          </div>
          <div class="col">
            <div class="card">
              <img src="assets/img/IMG_4636-min_1200x800.JPG" class="img-thumbnail rounded mx-auto d-block" alt="...">
            </div>
          </div>
          <div class="col">
            <div class="card">
              <img src="assets/img/IMG_4630-min_1200x800.JPG" class="img-thumbnail rounded mx-auto d-block" alt="...">
            </div>
          </div>
        </div>
      </div>
      <div class="container-fluid">
        <div class="row row-cols-1 row-cols-sm-2 row-cols-md-3 row-cols-lg-3 g-6">
          <div class="col">
            <div class="card">
              <img src="assets/img/IMG_4625-min_1200x800.JPG" class="img-thumbnail rounded mx-auto d-block" alt="...">
            </div>
          </div>
          <div class="col">
            <div class="card">
              <img src="assets/img/IMG_4627-min_1200x800.JPG" class="img-thumbnail rounded mx-auto d-block" alt="...">
            </div>
          </div>
          <div class="col">
            <div class="card">
              <img src="assets/img/IMG_4624-min_1200x800.JPG" class="img-thumbnail rounded mx-auto d-block" alt="...">
            </div>
          </div>
        </div>
        <div class="container-fluid">
        <div class="row row-cols-1 row-cols-sm-2 row-cols-md-3 row-cols-lg-3 g-6">
          <div class="col">
            <div class="card">
              <img src="assets/img/IMG_4660-min_1200x800.JPG" class="img-thumbnail rounded mx-auto d-block" alt="...">
            </div>
          </div>
          <div class="col">
            <div class="card">
              <img src="assets/img/IMG_4648-min_1200x800.JPG" class="img-thumbnail rounded mx-auto d-block" alt="...">
            </div>
          </div>
          <div class="col">
            <div class="card">
              <img src="assets/img/IMG_4621-min_1200x800.JPG" class="img-thumbnail rounded mx-auto d-block" alt="...">
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- /photos -->

  <div class="container py-3">
    <div id="omgeving" class="border rounded-3 bg-primary x-top-buffer">
      <div class="row">
        <div class="py-4 ms-auto text-center"><h6 class="display-6 fw-normal">De omgeving</h6>
<!--          <p class="fs-5 text-muted">Doe moar kalm an, ’t is vakantie!</p>-->
        </div>
      </div>
      <div class="row row-cols-1 justify-content-between mb-3">
        <div class="col d-flex align-items-stretch">
          <div class="card w-100 m-4 rounded-3 shadow-sm">
            <div class="card-body">
              <p>Midden-Drenthe</p>
              <p><small class="text-muted fw-ligth">Het bungalowpark ligt in de prachtige gemeente Midden-Drenthe. Laat je verrassen door de veelzijdigheid van deze regio! Hier vind je rust, ruimte en natuur in overvloed, maar ook gezellige dorpen en bruisende steden. Verken de uitgestrekte bossen en heidevelden tijdens een wandeling of fietstocht en ontdek de rijke geschiedenis van de regio in het Hunebedcentrum of voormalig Kamp Westerbork.</small></p>
              <p><small class="text-muted fw-ligth">
                Bezoek het monumentendorp Orvelte en maak kennis met het leven van vroeger, bekijk daar de allerkleinste dierentuin van Nederland in Zoo Bizar of ga op expeditie in Wildlands Adventure Zoo in Emmen. Bovendien is Midden-Drenthe een ideale uitvalsbasis om de rest van de provincie te verkennen. Met tweeduizend kilometer aan fietspaden is Drenthe de perfecte plek voor fietsliefhebbers, terwijl de steden Assen en Emmen volop mogelijkheden bieden voor cultuurliefhebbers.</small></p>
              <p><small class="text-muted fw-ligth">Kortom, Midden-Drenthe is dé bestemming voor een veelzijdige vakantie of weekendje weg!</small></p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
