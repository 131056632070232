import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {HuisjeComponent} from './_pages/huisje/huisje.component';
import {HomeComponent} from './_pages/home/home.component';
import {NotFoundComponent} from './_pages/not-found/not-found.component';
import {ParkComponent} from './_pages/park/park.component';

// const routes: Routes = [];

/**
 * routes, see guidelines
 * note: first match wins, so order is important
 */
const routes: Routes = [
  {
    path:  '',
    pathMatch:  'full',
    component:  HomeComponent
  },
  {
    path: 'huisje',
    component: HuisjeComponent
  },
  {
    path: 'park',
    component: ParkComponent
  },
  // all other routes and finally at the last add
  {
    path: '**',
    component: NotFoundComponent
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
